(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/namespace/assets/javascripts/namespace.js') >= 0) return;  svs.modules.push('/components/utils/namespace/assets/javascripts/namespace.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.utils = svs.utils || {};
  svs.utils.ns = svs.utils.ns || {};

  svs.utils.ns.isNamespace = function(ns, context) {
    var parts = ns.split('.');
    var part;

    for (var i = 0, len = parts.length, obj = context || window; i < len; ++i) {
      part = parts[i];
      obj = obj[part];

      if (obj === undefined || obj === null) {
        return false;
      }
    }

    return true;
  };

  if (typeof exports === 'object') {
    module.exports = svs.utils.ns;
  }
})(svs);


 })(window);